import React, { useState, Suspense } from 'react';

import loadable, { lazy } from '@loadable/component';
import MetaTags from 'react-meta-tags';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import './App.css';
import LoadingRoute from './components/LoadingRoute';
import { useViewFort } from './hooks';
import Loading from './views/Loading';

import { PageView, initGA } from './index';

const Home = lazy(() => import('./views/Home'));
const Press = lazy(() => import('./views/Press'));
const Contact = lazy(() => import('./views/Contact'));
const EasyDeep = lazy(() => import('./views/EasyDeep'));
const Blog = lazy(() => import('./views/Blog'));
const Career = lazy(() => import('./views/Career'));
const Team = lazy(() => import('./views/Team'));
const NotFound = lazy(() => import('./views/NotFound'));

const Header = loadable(() => import('./Header'));
const Footer = loadable(() => import('./Footer'));

export const NavCont = styled.div`
  position: fixed;
  right: ${(props) => (props.isMobile ? '20px' : '50px')};
  bottom: ${(props) => (props.isMobile ? '50px' : '30px')};
  z-index: 30;
`;

const KakaoButton = styled.img`
  width: 130px;
  @media only screen and (max-width: 768px) {
    width: 80px;
  } ;
`;

const BlackBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 50;
  opacity: 0.6;
`;

function App() {
  const { width } = useViewFort();
  const isMobile = width <= 768;
  const [isMobileShown, setIsMobileShown] = useState(false);

  initGA();
  PageView();
  const handleClick = () => {
    const Slack = require('slack-node');

    const webhookUrl =
      'https://hooks.slack.com/services/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';
    const slack = new Slack();
    slack.setWebhook(webhookUrl);
    const send = (message) => {
      slack.webhook(
        {
          channel: '#customer-feedback',
          usename: 'webhookbot',
          text: message,
        },
        function (err, response) {
          console.log(response);
        },
      );
    };
    send('algorima.io를 통해 접속한 유저가 카톡을 통해 상담을 요청합니다!');
  };
  return (
    <div>
      <MetaTags>
        <meta
          name="keywords"
          content="algorima, easydeep, AI, 인공지능, 교육, Algorima, Easydeep"
        />
        <meta name="description" content="Make AI work for the rest of us." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="알고리마" />
        <meta
          property="og:description"
          content="Make AI work for the rest of us."
        />
        <meta property="og:url" content="http://algorima.io" />
      </MetaTags>
      {isMobileShown && <BlackBackground></BlackBackground>}
      <NavCont isMobile={isMobile}>
        <KakaoButton
          onClick={() => handleClick()}
          src={
            isMobile ? '/images/MobileTalkButton.png' : '/images/TalkButton.png'
          }
          id="kakao-talk-channel-chat-button"
        />
      </NavCont>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/build" />
            <Route path="/">
              <Suspense fallback={<></>}>
                <Header
                  isMobileShown={isMobileShown}
                  setIsMobileShown={setIsMobileShown}
                />
              </Suspense>
            </Route>
          </Switch>

          <Switch>
            <Route exact path="/">
              <Suspense fallback={<Loading isMobile={isMobile} />}>
                <Home />
              </Suspense>
            </Route>
            <LoadingRoute path="/easydeep" isMobile={isMobile}>
              <EasyDeep />
            </LoadingRoute>
            <LoadingRoute path="/team" isMobile={isMobile}>
              <Team />
            </LoadingRoute>
            <LoadingRoute path="/press" isMobile={isMobile}>
              <Press />
            </LoadingRoute>
            <LoadingRoute path="/blog" isMobile={isMobile}>
              <Blog />
            </LoadingRoute>
            <LoadingRoute path="/career" isMobile={isMobile}>
              <Career />
            </LoadingRoute>
            <LoadingRoute path="/contact" isMobile={isMobile}>
              <Contact />
            </LoadingRoute>
            <LoadingRoute isMobile={isMobile}>
              <NotFound />
            </LoadingRoute>
          </Switch>

          <Switch>
            <Route path="/build" />
            <Route path="/">
              <Suspense fallback={<></>}>
                <Footer />
              </Suspense>
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}
export default App;
