import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';

import ReactDOM from 'react-dom';
import './index.css';
import './fonts/SpoqaHanSansNeo/SpoqaHanSans.css';
import './fonts/NotoSanaCJKkr/NotoSansCJKkr.css';
import './fonts/NanumSquare/nanumsquare.css';
import ReactGA from 'react-ga';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import i18n from './i18n';

// For linking the react app with Google Analytics
export const initGA = () => {
  ReactGA.initialize('UA-154888637-1');
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('root'),
);
