import React from 'react';

import styled, { keyframes } from 'styled-components';

import Container from '../Container';
import { Bannerstore } from '../stores';

const Cont = styled(Container)``;

const LoadingCont = styled.div`
  width: ${(props) => (props.isMobile ? 70 : 60)}%;
  margin: ${(props) =>
      props.isMobile ? 100 : props.bannerRemove ? 150 : 176}px
    auto 80px auto;
`;

const BoxAnimation = keyframes`
  0% {background:#eff2f5;}
  100% {background:#fbfbfb;}
`;

const Box = styled.div`
  background-color: #eff2f5;
  border-radius: 5px;
  animation: ${BoxAnimation} 1s 1s infinite alternate;
`;

const BoxesGridCont = styled.div`
  display: flex;
  flex-direction: row;
`;

const GridBoxes = styled.div`
  width: 25%;
  & + & {
    margin-left: 20px;
  }
`;

const ListBoxes = styled.div`
  width: 100%;
  margin-left: 10px;
`;

const FooterCont = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FooterBox = styled.div`
  width: 100px;
  height: 40px;
  background-color: #eff2f5;
  border-radius: 20px;
  margin: 40px 0px 100px 0px;
  float: right;
  animation: ${BoxAnimation} 1s 1s infinite alternate;
`;

function Loading({ isMobile }) {
  const bannerRemove = Bannerstore.bannerRemove;
  return (
    <Cont>
      <LoadingCont isMobile={isMobile} bannerRemove={bannerRemove}>
        {isMobile ? (
          <>
            <Box
              style={{ width: '30%', height: '20px', marginBottom: '10px' }}
            />
            <Box
              style={{ width: '60%', height: '20px', marginBottom: '20px' }}
            />
            <Box
              style={{ width: '100%', height: '180px', marginBottom: '10px' }}
            />

            <BoxesGridCont>
              <Box style={{ width: '15px', height: '15px' }} />
              <ListBoxes>
                <Box
                  style={{
                    width: '100%',
                    height: '15px',
                    marginBottom: '10px',
                  }}
                />
                <Box
                  style={{ width: '50%', height: '15px', marginBottom: '10px' }}
                />
              </ListBoxes>
            </BoxesGridCont>
            <BoxesGridCont>
              <Box style={{ width: '15px', height: '15px' }} />
              <ListBoxes>
                <Box
                  style={{
                    width: '100%',
                    height: '15px',
                    marginBottom: '10px',
                  }}
                />
                <Box
                  style={{ width: '50%', height: '15px', marginBottom: '10px' }}
                />
              </ListBoxes>
            </BoxesGridCont>
            <BoxesGridCont>
              <Box style={{ width: '15px', height: '15px' }} />
              <ListBoxes>
                <Box
                  style={{
                    width: '100%',
                    height: '15px',
                    marginBottom: '10px',
                  }}
                />
                <Box
                  style={{ width: '50%', height: '15px', marginBottom: '50px' }}
                />
              </ListBoxes>
            </BoxesGridCont>
            <Box
              style={{ width: '70%', height: '20px', marginBottom: '10px' }}
            />
            <Box style={{ width: '80%', height: '20px' }} />
          </>
        ) : (
          <>
            <Box
              style={{ width: '70%', height: '48px', marginBottom: '20px' }}
            />
            <Box
              style={{ width: '60%', height: '48px', marginBottom: '50px' }}
            />
            <Box
              style={{ width: '50%', height: '20px', marginBottom: '10px' }}
            />
            <Box
              style={{ width: '40%', height: '20px', marginBottom: '10px' }}
            />
            <Box
              style={{ width: '40%', height: '20px', marginBottom: '120px' }}
            />
            <Box
              style={{ width: '30%', height: '48px', marginBottom: '40px' }}
            />
            <BoxesGridCont>
              <GridBoxes>
                <Box
                  style={{
                    width: '100%',
                    height: '200px',
                    marginBottom: '10px',
                  }}
                />
                <Box
                  style={{ width: '80%', height: '20px', marginBottom: '10px' }}
                />
                <Box style={{ width: '80%', height: '20px' }} />
              </GridBoxes>
              <GridBoxes>
                <Box
                  style={{
                    width: '100%',
                    height: '200px',
                    marginBottom: '10px',
                  }}
                />
                <Box
                  style={{ width: '80%', height: '20px', marginBottom: '10px' }}
                />
                <Box style={{ width: '80%', height: '20px' }} />
              </GridBoxes>
              <GridBoxes>
                <Box
                  style={{
                    width: '100%',
                    height: '200px',
                    marginBottom: '10px',
                  }}
                />
                <Box
                  style={{ width: '80%', height: '20px', marginBottom: '10px' }}
                />
                <Box style={{ width: '80%', height: '20px' }} />
              </GridBoxes>
              <GridBoxes>
                <Box
                  style={{
                    width: '100%',
                    height: '200px',
                    marginBottom: '10px',
                  }}
                />
                <Box
                  style={{ width: '80%', height: '20px', marginBottom: '10px' }}
                />
                <Box style={{ width: '80%', height: '20px' }} />
              </GridBoxes>
            </BoxesGridCont>
          </>
        )}
        <FooterCont>
          <FooterBox />
        </FooterCont>
      </LoadingCont>
    </Cont>
  );
}

export default Loading;
