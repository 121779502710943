import React, { Suspense } from 'react';

import { Route } from 'react-router-dom';

import Loading from '../views/Loading';

const LoadingRoute = (props) => {
  return (
    <Route path={props.path}>
      <Suspense fallback={<Loading isMobile={props.isMobile} />}>
        {props.children}
      </Suspense>
    </Route>
  );
};

export default LoadingRoute;
